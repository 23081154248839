<template>
  <div
    class="flex fixed top-0 left-0 justify-center items-center w-screen h-screen max-w-screen"
    style="z-index: 7000; background-color: rgba(169, 169, 169, 0.5)"
    @click="closeModal"
  >
    <div class="c-container c-container--small" @click.stop>
      <slot />
    </div>
  </div>
</template>

<script>
import log from "@/lib/log";

export default {
  name: "Modal",

  methods: {
    closeModal() {
      log("closeModal");

      this.$emit("closeModal");
    },
  },
};
</script>
